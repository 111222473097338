// Fabric Type - Plant-Based, Animal-Based, Synthetic

import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/Layout"
import CTA from "../components/CTA"
import Reel from "../components/Reel"
import SEO from "../components/SEO"
import TextBlock from "../components/TextBlock"
import "./fabricType.scss"

const FabricTypeTemplate = (props) => {
  const { data } = props
  const fabricType = data.markdownRemark.frontmatter
  const siteTitle = data.site.siteMetadata?.title || `Fabricsthan`
  const pageTitle = fabricType.title
  // const { previous, next } = data
  const fabricBases = data.allMarkdownRemark.edges?.length
    ? data.allMarkdownRemark.edges.map((i) => ({
        name: i.node.frontmatter.title,
        slug: i.node.fields.slug,
        img: i.node.frontmatter.thumbnail,
      }))
    : []

  return (
    <Layout bgAlt={siteTitle}>
      <SEO
        title={`${pageTitle} | ${siteTitle} | Fabrics of India`}
        description={fabricType.meta_description}
        image={fabricType.thumbnail}
        pageContext={props.pageContext}
      />
      <div id="fabric-type-page" className="container">
        <div>
          <p className="sub-heading">Fabric Types</p>
          <h1>{pageTitle}</h1>
          <TextBlock
            title={fabricType.block_1_title}
            text={fabricType.block_1_text}
            imgSrc={fabricType.block_1_image}
            extraClass="text-block-1"
          />
          <TextBlock
            title={fabricType.block_2_title}
            text={fabricType.block_2_text}
            imgSrc={fabricType.block_2_image}
            extraClass="text-block-2"
          />
        </div>
        <h3 id="checkout-fabrics">Checkout common {pageTitle} fabrics</h3>
        <Reel items={fabricBases} showAsTiles />
        <CTA
          heading="Want to see fabrics from the states in India?"
          buttonLabel="Fabrics Of India"
          buttonLink="/fabrics-of-india"
        />
      </div>
    </Layout>
  )
}

export default FabricTypeTemplate
export const pageQuery = graphql`
  query FabricTypePost(
    $id: String!
    $title: String
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    # Fetch bases that have this fabric type
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/fabric_base/.*.md/" }
        frontmatter: { fabric_type: { eq: $title } }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            thumbnail
          }
        }
      }
    }
    # Fetch fabric type info
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        block_1_title
        block_1_text
        block_1_image
        block_2_title
        block_2_text
        block_2_image
        title
        thumbnail
        meta_description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
